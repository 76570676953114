.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.session-list-item {
    padding: 10px 10px;
    margin-bottom: 5px;
}

.game-item {
    padding: 10px 10px;
}

.player-icon {
    /* Default values, to be overriden */
    background: #000;
    color: white;
    opacity: 1;
}

[data-initials]:before {
    content: attr(data-initials);
}

[data-initials] {
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    border-radius: 50%;
    
    margin-right: 0.5em;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
}